var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { Component } from "react";
import styled from "styled-components";
import Menu from "../components/Menu";
import VideoItem from "../components/VideoItem";
import InfiniteScroll from "react-infinite-scroll-component";
var media = {
    screen_600: "@media(max-width: 600px)",
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n  width: 100%;\n"], ["\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n  width: 100%;\n"])));
var InfiniteScroller = styled(InfiniteScroll)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  width: 95%;\n  margin: 0 auto;\n  ", " {\n    width: 91%;\n  }\n"], ["\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  width: 95%;\n  margin: 0 auto;\n  ", " {\n    width: 91%;\n  }\n"])), media.screen_600);
var Footer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  margin-top: auto;\n  color: white;\n  background-color: #1a191a;\n  font-family: Prima Sans;\n"], ["\n  display: flex;\n  width: 100%;\n  margin-top: auto;\n  color: white;\n  background-color: #1a191a;\n  font-family: Prima Sans;\n"])));
var FooterContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  height: 70px;\n  width: 95%;\n  margin: 0 auto;\n  ", " {\n    width: 91%;\n  }\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  height: 70px;\n  width: 95%;\n  margin: 0 auto;\n  ", " {\n    width: 91%;\n  }\n"])), media.screen_600);
var FooterLeftItem = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
var SpanWhite = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", " {\n    font-size: 10px;\n  }\n"], ["\n  ", " {\n    font-size: 10px;\n  }\n"])), media.screen_600);
var LinkWhite = styled.a(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\ntext-decoration: none;\ncolor: white;\n  ", " {\n    font-size: 10px;\n  }\n"], ["\ntext-decoration: none;\ncolor: white;\n  ", " {\n    font-size: 10px;\n  }\n"])), media.screen_600);
var SpanGray = styled.span(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  color: gray;\n  font-size: 14px;\n  ", " {\n    font-size: 10px;\n  }\n"], ["\n  color: gray;\n  font-size: 14px;\n  ", " {\n    font-size: 10px;\n  }\n"])), media.screen_600);
var VideosPage = /** @class */ (function (_super) {
    __extends(VideosPage, _super);
    function VideosPage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            videos: [],
            nextPageToken: "",
        };
        return _this;
    }
    VideosPage.prototype.componentDidMount = function () {
        this.asyncLoadVideos(true);
    };
    VideosPage.prototype.asyncLoadVideos = function (isFirstLoad) {
        return __awaiter(this, void 0, void 0, function () {
            var url, res, data, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.state.nextPageToken && !isFirstLoad) {
                            return [2 /*return*/];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        url = isFirstLoad ? "/api/videos.php" : "/api/videos.php?pageToken=" + this.state.nextPageToken;
                        return [4 /*yield*/, fetch(url)];
                    case 2:
                        res = _a.sent();
                        return [4 /*yield*/, res.json()];
                    case 3:
                        data = _a.sent();
                        this.setState({
                            videos: this.state.videos.concat(data.items),
                            nextPageToken: data.nextPageToken,
                        });
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _a.sent();
                        throw new Error('An error occurred, please reload the page');
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    VideosPage.prototype.render = function () {
        var _this = this;
        return (React.createElement(Container, null,
            React.createElement(Menu, null),
            React.createElement(InfiniteScroller, { dataLength: this.state.videos.length, next: function () {
                    _this.asyncLoadVideos(false);
                }, hasMore: true, loader: React.createElement("h4", null, "Loading...") }, this.state.videos.map(function (el, index) { return (React.createElement(VideoItem, { video: el, key: index })); })),
            React.createElement(Footer, null,
                React.createElement(FooterContainer, null,
                    React.createElement(FooterLeftItem, null,
                        React.createElement(SpanWhite, null, "TVideoArt Daniel Pe\u0161ta"),
                        React.createElement(SpanGray, null, "\u00A9 2020 Daniel Pe\u0161ta")),
                    React.createElement(LinkWhite, { href: "https://danielpesta.com/en" }, "danielpesta.com")))));
    };
    return VideosPage;
}(Component));
export default VideosPage;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
