var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from "react";
import styled from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";
import noImage from "../images/no_image.png";
var media = {
    r1500: "@media only screen and (max-width: 1500px)",
    r1400: "@media only screen and (max-width: 1400px)",
    r1300: "@media only screen and (max-width: 1300px)",
    r1200: "@media only screen and (max-width: 1200px)",
    r1100: "@media only screen and (max-width: 1100px)",
    r800: "@media only screen and (max-width: 800px)",
    r700: "@media only screen and (max-width: 700px)",
    r600: "@media only screen and (max-width: 600px)",
    r500: "@media only screen and (max-width: 500px)",
    r400: "@media only screen and (max-width: 400px)",
    r300: "@media only screen and (max-width: 300px)",
};
var VideoContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  font-family: Prima Sans;\n  box-sizing: border-box;\n  padding: 7px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  font-family: Prima Sans;\n  box-sizing: border-box;\n  padding: 7px;\n"])));
var VideoTitle = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 25px;\n  color: white;\n  margin-top: 8px;\n  width: 100%;\n  ", " {\n    font-size: 18px;\n  }\n  ", " {\n    font-size: 21px;\n  }\n  ", " {\n    font-size: 18px;\n  }\n  ", " {\n    font-size: 14px;\n  }\n"], ["\n  font-size: 25px;\n  color: white;\n  margin-top: 8px;\n  width: 100%;\n  ", " {\n    font-size: 18px;\n  }\n  ", " {\n    font-size: 21px;\n  }\n  ", " {\n    font-size: 18px;\n  }\n  ", " {\n    font-size: 14px;\n  }\n"])), media.r1400, media.r1200, media.r700, media.r400);
var Link = styled.a(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex-basis: 33.333%;\n  text-decoration: none;\n  margin-bottom: 20px;\n  ", " {\n    flex-basis: 50%;\n  }\n  ", " {\n    flex-basis: 100%;\n  }\n"], ["\n  flex-basis: 33.333%;\n  text-decoration: none;\n  margin-bottom: 20px;\n  ", " {\n    flex-basis: 50%;\n  }\n  ", " {\n    flex-basis: 100%;\n  }\n"])), media.r1200, media.r700);
var Image = styled(LazyLoadImage)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  max-width: 100%;\n  max-height: 100%;\n"], ["\n  max-width: 100%;\n  max-height: 100%;\n"])));
var VideoItem = /** @class */ (function (_super) {
    __extends(VideoItem, _super);
    function VideoItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {};
        return _this;
    }
    VideoItem.prototype.render = function () {
        var _a, _b, _c;
        return (React.createElement(Link, { href: this.props.video.url || " ", target: "_blank" },
            React.createElement(VideoContainer, null,
                React.createElement(Image, { src: ((_a = this.props.video) === null || _a === void 0 ? void 0 : _a.thumbnail) || noImage, alt: (_b = this.props.video) === null || _b === void 0 ? void 0 : _b.title }),
                React.createElement(VideoTitle, null, (_c = this.props.video) === null || _c === void 0 ? void 0 : _c.title))));
    };
    return VideoItem;
}(Component));
export default VideoItem;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
