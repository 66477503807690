var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import styled from "styled-components";
import { Link, Redirect } from "react-router-dom";
import introImgSrc from "../images/intro.png";
import soundTrue from "../images/sound_true.png";
import soundFalse from "../images/sound_false.png";
import introVideo from "../files/last try.mp4";
var media = {
    screen_1366: "@media(max-width: 1366px)",
    screen_900: "@media(max-width: 900px)",
    screen_400: "@media(max-width: 400px)",
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex:1;\n    justify-content: center;\n    text-align: center;\n"], ["\n    display: flex;\n    flex:1;\n    justify-content: center;\n    text-align: center;\n"])));
var IntroImg = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: 5%;\n  ", " {\n    width: 100%;\n    height: auto;\n  }\n  ", " {\n    width: 70%;\n    height: auto;\n    align-self: center;\n  }\n"], ["\n  margin-top: 5%;\n  ", " {\n    width: 100%;\n    height: auto;\n  }\n  ", " {\n    width: 70%;\n    height: auto;\n    align-self: center;\n  }\n"])), media.screen_1366, media.screen_900);
var IntroVideo = styled.video(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-top: 5%;\n  ", " {\n    width: 90%;\n    height: auto;\n  }\n  ", " {\n    width: 80%;\n    height: auto;\n    align-self: center;\n  }\n"], ["\n  margin-top: 5%;\n  ", " {\n    width: 90%;\n    height: auto;\n  }\n  ", " {\n    width: 80%;\n    height: auto;\n    align-self: center;\n  }\n"])), media.screen_1366, media.screen_900);
var SoundImg = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: absolute;\n  right: 24px;\n  top: 10px;\n  width: 30px;\n  height: 30px; \n  ", " {\n    width: 20px;\n    height: 20px;\n    right: 14px; \n  }\n  ", " {\n    right: 10px;\n    top: 4px;\n  }\n"], ["\n  position: absolute;\n  right: 24px;\n  top: 10px;\n  width: 30px;\n  height: 30px; \n  ", " {\n    width: 20px;\n    height: 20px;\n    right: 14px; \n  }\n  ", " {\n    right: 10px;\n    top: 4px;\n  }\n"])), media.screen_900, media.screen_400);
var PlaySoundText = styled.span(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: absolute;\n  right: 70px;\n  top: 2px;\n  font-family: Prima Sans;\n  width: 120px;\n  background-color: gray;\n  color: #fff;\n  text-align: center;\n  padding: 5px 0;\n  border-radius: 6px;\n  ::after {\n    content: \" \";\n    position: absolute;\n    top: 50%;\n    left: 100%; /* To the right of the tooltip */\n    margin-top: -5px;\n    border-width: 5px;\n    border-style: solid;\n    border-color: transparent transparent transparent gray;\n  }\n  ", " {\n    right: 40px;\n    opacity: 0.6; \n  }\n"], ["\n  position: absolute;\n  right: 70px;\n  top: 2px;\n  font-family: Prima Sans;\n  width: 120px;\n  background-color: gray;\n  color: #fff;\n  text-align: center;\n  padding: 5px 0;\n  border-radius: 6px;\n  ::after {\n    content: \" \";\n    position: absolute;\n    top: 50%;\n    left: 100%; /* To the right of the tooltip */\n    margin-top: -5px;\n    border-width: 5px;\n    border-style: solid;\n    border-color: transparent transparent transparent gray;\n  }\n  ", " {\n    right: 40px;\n    opacity: 0.6; \n  }\n"])), media.screen_900);
var IntroImgLink = styled(Link)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n\n"], ["\n\n"])));
var IntroTextContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\ncursor: pointer;\nfont-family: Helvetica,serif;\ndisplay: block;\ncolor: white;\nfont-size: 5.5vw;\npadding: 20%;\ntext-align: center;\ntext-decoration: none;\nletter-spacing: 0.1vw;\n:hover {\n  text-decoration: underline;\n}\n"], ["\ncursor: pointer;\nfont-family: Helvetica,serif;\ndisplay: block;\ncolor: white;\nfont-size: 5.5vw;\npadding: 20%;\ntext-align: center;\ntext-decoration: none;\nletter-spacing: 0.1vw;\n:hover {\n  text-decoration: underline;\n}\n"])));
var HomePage = /** @class */ (function (_super) {
    __extends(HomePage, _super);
    function HomePage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            isPlaying: false,
            showVideo: false,
            videoEnded: false
        };
        _this.audioRef = React.createRef();
        return _this;
    }
    HomePage.prototype.toggleSound = function () {
        this.setState({
            isPlaying: !this.state.isPlaying
        });
        this.audioRef.current.muted = !!this.state.isPlaying;
    };
    HomePage.prototype.onWelcomeClick = function () {
        this.setState({
            showVideo: true
        });
    };
    HomePage.prototype.onPlay = function () {
        this.setState({
            isPlaying: !this.audioRef.current.muted
        });
    };
    HomePage.prototype.render = function () {
        var _this = this;
        if (!this.state.showVideo) {
            return React.createElement(IntroTextContainer, { onClick: function () { return _this.onWelcomeClick(); } }, "Welcome to Daniel\u00A0Pesta\u00A0TV");
        }
        if (this.state.videoEnded) {
            return React.createElement(Redirect, { to: "/videos" });
        }
        return React.createElement(Container, null,
            React.createElement(IntroImgLink, { to: "/videos" },
                React.createElement(IntroVideo, { ref: this.audioRef, onPlay: function () { return _this.onPlay(); }, onEnded: function () { return _this.setState({ videoEnded: true }); }, src: introVideo, poster: introImgSrc, autoPlay: true, playsInline: true },
                    React.createElement(IntroImg, { src: introImgSrc }))),
            React.createElement(SoundImg, { src: this.state.isPlaying ? soundTrue : soundFalse, onClick: function () { _this.toggleSound(); } }),
            !this.state.isPlaying &&
                React.createElement(PlaySoundText, null, "You can turn on the sound"));
    };
    return HomePage;
}(Component));
export default HomePage;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
